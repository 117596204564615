import React from "react"

import { Hero } from "../../components/blog/hero"
import { PostSidebar } from "../../components/blog/post-sidebar"
import { Breadcrumbs, BreadcrumbsItem } from "../../components/breadcrumbs"
import { Container } from "../../components/container"
import SEO from "../../components/seo"
import img1 from "./images/4-min.jpg"
import styles from "./post-grid.module.css"

const VitaminD = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={"Vitamin D | NutritionPro"}
        description={
          "Vitamin D se řadí mezi lipofilní vitaminy, tedy vitaminy rozpustné v tucích. Je velmi důležitým mikronutrientem, který hraje zásadní roli ve fungování lidského organismu. Přirozeně se nachází v limitovaném množství potravin, často je proto nutné jej suplementovat, a to i přesto, že si ho lidské tělo umí částečně samo vytvořit."
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: "24px 0" }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/zdrave-udrzitelne-hubnuti">
            Vitamin D
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Vitamin D" date="08.02.2023" />
            <div>
              <h5 className={styles.postTitle}>O vitaminu D</h5>
              <p className={styles.postText}>
                Vitamin D se řadí mezi <b>lipofilní vitaminy,</b> tedy vitaminy
                rozpustné v tucích. Je velmi důležitým mikronutrientem, který
                hraje <b>zásadní roli ve fungování lidského organismu.</b>{" "}
                Přirozeně se nachází v limitovaném množství potravin, často je
                proto nutné jej suplementovat, a to i přesto, že si ho lidské
                tělo umí částečně samo vytvořit.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Pokud vás zajímají vitaminy a jejich význam v lidské
                výživě, přečtěte si také o vitaminech skupiny B.
              </p>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: "40px" }}
                alt="img1"
              />
              <h5 className={styles.postTitle}>Metabolismus vitaminu D</h5>
              <p className={styles.postText}>
                Ačkoliv vitamin D přijímáme ve stravě, určité množství si je{" "}
                <b>člověk schopen vytvořit sám.</b> V těle vzniká z prekurzorů,
                které pochází z cholesterolu a také mohou být tvořeny kvasinkami
                a plísněmi. <b>Vitamin vzniká v kůži působením UVB záření.</b>{" "}
                Množství vytvořeného vitaminu je přímo úměrné délce vystavení
                kůže slunečnímu záření, jsou proto určitá období, kdy se
                vitaminu vytvoří <b>dostačující množství,</b> a naopak období,
                kdy nikoliv. Nejhojnějšími měsíci jsou v České republice{" "}
                <b>červen, červenec a srpen.</b> Po zbytek roku je nutné vitamin
                D přijímat potravou, <b>doporučená je i suplementace,</b> a to u
                všech věkových kategorií, především pak v zimních měsících. Jako
                optimální se jeví <b>80% doporučené denní dávky</b> vitaminu
                přijmout <b>ze slunečního záření</b> a zbylých{" "}
                <b>20% ze stravy.</b> Pro pokrytí denního příjmu vitaminu D
                stačí cca <b>20 minut na slunci,</b> přičemž musí být{" "}
                <b>odkryto alespoň 40 % těla.</b> Záleží ovšem i na zeměpisné
                šířce, ročním období, denní době a pigmentaci kůže. Produkci
                vitaminu mohou také snižovat opalovací krémy, které jsou ovšem
                pro ochranu kůže nutností.
              </p>

              <p className={styles.postText}>
                Existují <b>2 formy</b> vitaminu D: D2 - <b>ergokalciferol,</b>{" "}
                který přijímáme potravou, a D3 - <b>cholekalciferol,</b> který
                též přijímáme potravou a také se vytváří v kůži. Obě tyto formy
                se v těle dostávají do jater a následně do ledvin, kde vzniká
                účinná forma vitaminu D - <b>kalcitriol.</b>
              </p>

              <h5 className={styles.postTitle}>Funkce kalcitriolu</h5>
              <p className={styles.postText}>
                Kalcitriol společně s hormony kalcitoninem a parathormonem{" "}
                <b>regulují metabolismus vápníku,</b> to znamená, že se účastní
                resorpce vápníku ze stravy, jeho ukládání do kostí a vylučování
                z těla. “Déčko” také <b>stimuluje resorpci vápníku a fosforu</b>{" "}
                z tenkého střeva. Hraje roli při stavbě kostí, dělení a
                diferenciace buněk a přispívá k udržení zdravého imunitního
                systému. Také pozitivně ovlivňuje mozkové synapse a renální
                funkci.
              </p>

              <h5 className={styles.postTitle}>Zdroje</h5>
              <p className={styles.postText}>
                D3 - <b>rybí tuk, maso tučných ryb</b> (tuňák, losos, makrela),
                játra některých ryb (treska), vaječný žloutek, máslo, mléko,
                obohacené margaríny, <b>UVB záření</b> (není samo zdrojem, díky
                němu se však v kůži začne vitamin tvořit)
              </p>

              <p className={styles.postText}>D2 - houby, kvasinky</p>

              <p className={styles.postText}>
                V Česku jsou u dospělé populace hlavním zdrojem vitaminu D{" "}
                <b>vejce,</b> u dětí je nejvýznamnějším zdrojem{" "}
                <b>mléko a mléčné výrobky.</b> Zanedbatelné nejsou ani ryby,
                ačkoliv je jejich spotřeba v ČR poměrně nízká.
              </p>

              <h5 className={styles.postTitle}>Denní potřeba</h5>
              <p className={styles.postText}>
                5 – 20 µg na den, vyšší potřeba je u dětí do 1 roku a u seniorů
                nad 65 let, biosyntéza v kůži se totiž výrazně snižuje s věkem.
              </p>

              <h5 className={styles.postTitle}>Nedostatek</h5>
              <p className={styles.postText}>
                Dietární zdroje vitaminu D <b>nepokryjí</b> doporučenou denní
                dávku až u 95 % populace ČR (u osob 4 - 90 let). Nedostatek
                vitaminu D je však problémem nejen v ČR, ale i{" "}
                <b>po celém světě.</b> V moderní době k němu významně přispívá{" "}
                <b>sedavý způsob života a obezita.</b> Část vitaminu D, která je
                uložena v tukové tkáni, je funkčně neaktivní, snižuje tak
                celkovou aktivitu vitaminu D v těle. Sedavý způsob života
                omezuje pohyb venku, tedy i vystavení slunečnímu záření.
              </p>

              <p className={styles.postText}>
                U dětí způsobuje nedostatek vitaminu D <b>křivici.</b> U
                starších osob může zapříčinit <b>osteomalacii,</b> osteoporózu a
                taky zvyšuje riziko nádorových, infekčních a autoimunitních
                onemocnění, jako je diabetes mellitus 1. typu.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> Dostatečný příjem všech potřebných mikronutrientů?{" "}
                <a href="https://nutritionpro.cz/" target="_blank">
                  NutritionPro krabičky!
                </a>
              </p>

              <h5 className={styles.postTitle}>Přebytek</h5>
              <p className={styles.postText}>
                Vitamin D patří mezi vitaminy rozpustné v tucích, to jsou
                vitaminy, kterými se člověk <b>může předávkovat.</b> Ukládají se
                v našem těle a není tak snadné je vyloučit, jako vitaminy
                rozpustné ve vodě. Toto ovšem platí jen u dietárních zdrojů.{" "}
                <b>
                  Dlouhodobá expozice slunečnímu záření intoxikaci vitaminem D
                  nezpůsobí.
                </b>
              </p>

              <p className={styles.postText}>
                Dávky vitaminu D vyšší než 50 µg jsou pro nás <b>toxické.</b>{" "}
                Otrava hrozí pouze u orálního příjmu. Následně dochází k{" "}
                <b>hyperkalcémii,</b> zvyšuje se neuromuskulární dráždivosti a
                riziko vzniku akutní pankreatitidy, žaludečních vředů a
                ledvinových kamenů.
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default VitaminD
